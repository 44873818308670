import SponsorSlide from "../components/sponsors/sponsor.slide";
import RequestForm from "../components/request/request.form";
import React from "react";
import {Card} from "react-bootstrap";


export default function Request() {
    return (
        <div className={'content'}>

            <div className={'container'}>

                <SponsorSlide/>

                <Card>
                    <RequestForm/>
                </Card>
            </div>
        </div>
    )
}