import SponsorSlide from "../components/sponsors/sponsor.slide";
import React, {Suspense, useState} from "react";
import {Route, Routes} from "react-router-dom";
import RequestList from "../components/request/request.list";
import RequestForm from "../components/request/request.form";

export default function Home() {
    const [doRequest, setDoRequest] = useState(false);

    return (
        <div className={'content'}>

            <div className={'container'}>

                <SponsorSlide/>

                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<RequestList/>}/>
                        <Route path="/request" element={<RequestForm onSubmit={() => setDoRequest(false)}/>}/>
                    </Routes>
                </Suspense>
            </div>
        </div>
    )
}