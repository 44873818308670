import React, {useEffect, useState} from "react";
import {Card, Container} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import Moment from "react-moment";
import RequestPlayedBadge from "../components/request/request.played.badge";

export default function Admin() {
    const [requests, setRequests] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async function fetchData() {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_API_URL}/request`, {
            method: 'GET', // or 'PUT'
            headers: {'Accept': 'application/json',},
        })
            .then(response => response.json())
            .then(data => setRequests(data))
            .then(data => setLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
            maxWidth: '30px'
        },
        {
            name: 'Datum',
            selector: row => row.createdAt,
            cell: row => <Moment format={"YYYY-MM-DD HH:mm"}>{row.createdAt}</Moment>,
            sortable: true,
            maxWidth: '170px'
        },
        {
            name: 'Naam',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Verzoekje',
            selector: row => row.request,
            sortable: true
        },
        {
            name: 'Afgespeeld',
            selector: row => row.played,
            sortable: true,
            cell: row => <RequestPlayedBadge request={row} />,
        },
    ]

    return (
        <>
            <Container fluid={"xl"}>
                <h1 className={'text-white'}>Admin</h1>

                <Card>
                    {requests == null ? loading : <DataTable
                        columns={columns}
                        data={requests}
                        defaultSortFieldId={1}
                        defaultSortAsc={false}
                        pagination
                    />}
                </Card>
            </Container>
        </>
    )
}