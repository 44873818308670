import React from "react";
import {Button, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useConfig} from "../../context/config.context";

export default function NavBar() {
    const config = useConfig();

    return (
        <Navbar className={'justify-content-between'}>
            <Link to={'/'}><img alt={'Strooteam FM'} src={'/assets/img/STROOTEAM-borstlogo.png'}/></Link>
            <Button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </Button>

            <div className={'text-center text-white'}>
                <h5>App met de studio: {config.phoneNumber}</h5>
            </div>
        </Navbar>
    )
}