import React from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ReactAudioPlayer from "react-audio-player";
import NavBar from "./components/navbar";
import {LoginProvider, Secured} from "./context/login.context";
import {ConfigProvider, useConfig} from "./context/config.context";
import Home from "./pages/home";
import Login from "./pages/login";
import Admin from "./pages/admin";
import Request from "./pages/request";

function App() {
    const config = useConfig();

    return (
        <>
            <Router>

                <div className={'d-flex flex-column'}>

                    <header>
                        <NavBar/>
                    </header>


                    <div className={'main'}>
                        <Routes>
                            <Route exact path="/" element={<Home/>} />
                            <Route exact path="/request" element={<Request/>} />
                            <Route exact path="/admin" element={<Secured redirect={true}><Admin/></Secured>} />
                            <Route exact path="/login" element={<Login/>} />
                        </Routes>
                    </div>

                    <footer>
                        <ReactAudioPlayer src={config.streamUrl} autoPlay controls/>
                    </footer>

                </div>
            </Router>
        </>
    );
}

export default ConfigProvider(LoginProvider(App));
