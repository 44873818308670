import {Badge} from "react-bootstrap";
import React, {useState} from "react";
import {useLogin} from "../../context/login.context";


export default function RequestPlayedBadge(props) {
    const [request, setRequest] = useState(props.request);
    const {loggedIn} = useLogin();

    const togglePlayed = () => {
        fetch(`${process.env.REACT_APP_API_URL}/request/toggle/${request.id}`, {
                method: 'PUT'
            })
            .then(response => response.json())
            .then(data => setRequest(data));
    }

    if(loggedIn) {
        if(!!request.played) {
            return <Badge variant={"success cursor-pointer"} onClick={togglePlayed}>Gedraaid</Badge>
        } else {
            return <Badge variant={"warning cursor-pointer"} onClick={togglePlayed}>Nog even geduld</Badge>
        }
    }

    if(!!request.played) {
        return <Badge variant={"success"}>Gedraaid</Badge>
    } else {
        return <Badge variant={"warning"}>Nog even geduld</Badge>
    }
}